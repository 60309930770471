import { RouteComponentProps, Router, useLocation } from '@reach/router'
import { useAuthContext, useLayoutContext, useUserContext } from '@tawal/lan-logic'
import { AppPath } from '@tawal/types'
import { Spinner } from '@tawal/ui'
import { onClientSide, redirectToLogIn } from '@tawal/utils'
import React from 'react'

import {
  AddPropertyOneStep,
  Contact,
  Delegates,
  ProfileDetails,
  Properties,
  Property,
  SignupOrganization,
  OrganizationProfile,
} from '@/views'

function LoadingScreen(_: RouteComponentProps) {
  return <Spinner size="large" />
}

function AppPage() {
  const { isAuthenticated } = useAuthContext()
  const { isAutoLoggingIn, isUserInitSuccess } = useUserContext()
  const { setOpenDrawer } = useLayoutContext()
  const location = useLocation()

  if (!isAutoLoggingIn && !isAuthenticated) {
    onClientSide(() => {
      redirectToLogIn(location)
      setOpenDrawer(null)
    })
  }

  return (
    <>
      <Router style={{ height: '100%' }} basepath="">
        {isAuthenticated && isUserInitSuccess ? (
          <>
            <AddPropertyOneStep path={AppPath.ADD_PROPERTY} />
            <Property path={`${AppPath.PROPERTIES}/:propertyId`} />
            <ProfileDetails path={AppPath.USER_PROFILE} />
            <OrganizationProfile path={AppPath.ORGANIZATION_PROFILE} />
            <SignupOrganization path={AppPath.ADD_ORGANIZATION} />
            <Delegates path={AppPath.DELEGATES} />
            <Properties path={AppPath.PROPERTIES} />
            <Contact path={AppPath.CONTACT} />
          </>
        ) : (
          <LoadingScreen default />
        )}
      </Router>
    </>
  )
}

export default AppPage
